.list-group-item {
  //margin-top: 1rem;
  color: #afaeae !important;
  border: none;
  //background-color: #f8f9fa;ndItem
  background-color: #f5f5f5 !important;
  font-size: 0.85rem;
  /*margin-left: 10px;
  margin-right: 10px;*/
  font-family: 'Nunito Sans';
  font-weight: bold;
  border-left: 4px solid #f5f5f5 !important;
}

.list-group-item.active {
  color: $primary !important;
  z-index: 0;
  // override primary color on active item
  border-color: rgba(0, 0, 0, 0.125);

  //active marker an front
  border-left: 4px solid $primary !important;
  font-family: 'Nunito Sans';
}

.list-group-item svg {
  font-size: 1rem;
}

.list-group-item-sub svg {
  font-size: 0.8rem;
}

.list-group-item .box-right {
  margin-left: auto;
  margin-right: auto;
}

.list-group-item:hover {
  //box-shadow: 0 3px 10px 0 rgba(21, 27, 38, .15);
  //border-left: 4px solid $primary !important;
  color: $primary;
  font-family: 'Nunito Sans';
}

.list-group-head {
  h5 {
    margin-left: 10px;
  }
  span {
    color: #afaeae;
  }
}

.list-group-item a {
  color: #000000;
  text-decoration: none;
  line-height: 1.6rem;
}

.list-group-item {
  color: #afaeae;
  font-weight: 400;
  font-family: 'RobotoLight', -apple-system;
  transition: all 0.2s linear 50ms;
}
.list-group-item .indent {
  margin-top: 12px;
}

.list-group-item .indent h6 {
  font-size: 1.2rem;
  margin-bottom: -2px;
}

.list-group-item .indent a span,
.list-group-item .indent a h6 {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  float: left;
  width: 100%;
}

.list-group-item .indent a span {
  min-height: 14px;
}

.list-group-item .col-3,
.list-group-item .col-md-2 {
  padding-left: 0px;
}

.list-group-item .box-right span {
  color: #000000;

  border-radius: 0.9rem;

  padding: 0;

  padding-left: 0px;

  width: 100px;

  text-align: center;

  padding-top: 4px;

  padding-bottom: 4px;

  margin-left: auto;
  margin-right: auto;
  margin-top: 0px;
}

.list-group-item .box-right h6.open {
  color: $bookitup-deep-orange;
}

/*.list-group-head h5 {

  text-align: center;

  padding: 8px;

  width: 200px;

  margin-left: auto;

  margin-right: auto;

  color: #000;
  text-transform: uppercase;
  font-family: "Nunito Sans";
}*/

// progressbar
.list-group-head .progress {
  height: 6px;
  width: 200px;

  margin-left: auto;

  margin-right: auto;
  margin-top: -12px;
  background-color: #ffffff00;
}

@media (max-width: 768px) {
  .list-group-head .progress {
    width: 160px;
  }
}

.hidden-bar.progress {
  margin-bottom: 2px;
}

.hidden-bar .progress-bar {
  background-color: #c3a3a300;
  color: #666;
  padding-bottom: 7px;
  margin-top: 9px;
}

.progress-bar.bg-success {
  background-color: $success;
}

.progress-bar.bg-warning {
  background-color: $warning !important;
}

.progress-bar.bg-secondary {
  background-color: #c0c0c0 !important;
}

.month-header {
  position: fixed;
  top: 40px;
  right: auto;
  left: auto;
  z-index: 2;
  width: 97%;
}

.month-header .list-group-head {
  margin-left: auto;
  margin-right: auto;
  background-color: $bookitup-deep-orange;
  width: 200px;
}

.month-header h5 {
  color: #fff;
}

.month-header .list-group-item {
  background: transparent;
  box-shadow: none;
  margin-top: 0px;
  padding-top: 4px;
}

.list-group-head {
  //display: none;
}

.list-group-item {
  //margin-top: 10px;
}

.list-group-item:first-child {
  margin-top: 5px;
}

.right-box {
  margin-left: auto;
  margin-right: 20px;
  z-index: 2;
}

.box-right svg {
  border-radius: 100%;
  //padding: 6px;
  //width: 2rem !important;
  //height: 2rem;
  color: #fff;
}

.box-right.message svg {
  color: #000;
  width: 2.5rem !important;
  height: 2.5rem;
}

.box-right.booked svg {
  background: linear-gradient(40deg, #a1e2a6, #a1e2a6) !important;
  border: 1px solid #6ba581;
}

.box-right span.booked,
.box-right span.offered,
.box-right span.open {
  font-size: 1rem;
}

@media (max-width: 768px) {
  .box-right span.booked,
  .box-right span.offered,
  .box-right span.open {
    font-size: 0.8rem;
  }
}

.box-right.open svg {
  background: linear-gradient(40deg, #97cbfc, #97cbfc) !important;
  border: 1px solid #5492cc;
}
.box-right.offered svg {
  background: linear-gradient(40deg, #ffd86f, #ffd86f) !important;
  border: 1px solid #e4ca99;
}

.box-right.canceled svg {
  background: rgb(255, 81, 79) !important;
  border: 1px solid rgb(255, 42, 33);
}

.box-right.recommended svg {
  background: rgb(200, 148, 178) !important;
  border: 1px solid rgb(173, 75, 128);
}

.box-right.earmarked svg {
  background: rgb(173, 173, 173) !important;
  border: 1px solid rgb(131, 131, 131);
}

.box-right.unavailable svg {
  background: rgb(173, 173, 173) !important;
  border: 1px solid rgb(131, 131, 131);
}

.unread {
  color: #ffcca5 !important;
}
.unread:hover {
  color: #ffd86f !important;
}
