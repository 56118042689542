// success
.css-3mfg68-Icon,
.css-17eabvo // Safari
{
  color: #53934e !important;
  background-color: rgb(217, 241, 217) !important;
}

.css-wo4ljk-ToastElement {
  color: #53934e !important;
  background-color: #f8fff8 !important;
}

.css-bj1hmp-Countdown {
}

// info
.css-1rjh2i2-Icon,
.css-pbi326 // Safari
{
  background-color: $primary !important;
}
.css-17jyn9g-ToastElement {
}

// warning
.css-1wh70f9-Icon,
.css-17ya987 // Safari
{
  background-color: #e8b470 !important;
}
.css-fwfomi-ToastElement {
  background-color: #ffffff !important;
  color: #e8b470 !important;
}

// error
.css-1cbc3rp-Icon,
.css-tpnslk // Safari
{
  background-color: #f07573 !important;
  color: #fff7f5 !important;
}
.css-9z2we-ToastElement {
  background-color: #fff7f5 !important;
  color: #f07573 !important;
}

.react-toast-notifications__toast__content {
  display: flex;
  align-items: center;
}
