.form-check-input[type='radio'] {
  position: absolute;
  margin-top: 0.3rem;
  margin-left: -1.25rem;
}

.form-check-input[type='radio']:checked + label,
.form-check-input[type='radio']:not(:checked) + label,
label.btn input[type='radio']:checked + label,
label.btn input[type='radio']:not(:checked) + label {
  position: relative;
  padding-left: 28px;
  cursor: pointer;
  display: inline-block;
  height: 1.5625rem;
  line-height: 1.5625rem;
  -webkit-transition: 0.28s ease;
  -o-transition: 0.28s ease;
  transition: 0.28s ease;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  float: left;
  width: 100%;
}

.form-check-input[type='radio']:not(:checked) + label::after,
.form-check-input[type='radio']:not(:checked) + label::before,
label.btn input[type='radio']:not(:checked) + label::after,
label.btn input[type='radio']:not(:checked) + label::before {
  border: 1px solid #5a5a5a;
}

.form-check-input[type='radio']:checked + label::before,
label.btn input[type='radio']:checked + label::before {
  border: 2px solid transparent;
}

.form-check-input[type='radio'].with-gap:checked + label::after,
.form-check-input[type='radio'].with-gap:checked + label::before,
.form-check-input[type='radio']:checked + label::after,
.form-check-input[type='radio']:checked + label::before,
.form-check-input[type='radio']:not(:checked) + label::after,
.form-check-input[type='radio']:not(:checked) + label::before,
label.btn input[type='radio'].with-gap:checked + label::after,
label.btn input[type='radio'].with-gap:checked + label::before,
label.btn input[type='radio']:checked + label::after,
label.btn input[type='radio']:checked + label::before,
label.btn input[type='radio']:not(:checked) + label::after,
label.btn input[type='radio']:not(:checked) + label::before {
  -webkit-border-radius: 50%;
  border-radius: 50%;
}

.form-check-input[type='radio'] + label::after,
.form-check-input[type='radio'] + label::before,
label.btn input[type='radio'] + label::after,
label.btn input[type='radio'] + label::before {
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  margin: 4px;
  width: 16px;
  height: 16px;
  z-index: 0;
  -webkit-transition: 0.28s ease;
  -o-transition: 0.28s ease;
  transition: 0.28s ease;
}

.form-check-input[type='radio']:checked + label::after,
label.btn input[type='radio']:checked + label::after {
  -webkit-transform: scale(1.02);
  -ms-transform: scale(1.02);
  transform: scale(1.02);
}

.form-check-input[type='radio'].with-gap:checked + label::after,
.form-check-input[type='radio']:checked + label::after,
label.btn input[type='radio'].with-gap:checked + label::after,
label.btn input[type='radio']:checked + label::after {
  background-color: $bookitup-deep-orange;
}

.form-check-input[type='radio'].with-gap:checked + label::after,
.form-check-input[type='radio'].with-gap:checked + label::before,
.form-check-input[type='radio']:checked + label::after,
label.btn input[type='radio'].with-gap:checked + label::after,
label.btn input[type='radio'].with-gap:checked + label::before,
label.btn input[type='radio']:checked + label::after {
  border: 2px solid $bookitup-deep-orange;
}

.form-check-input[type='radio'].with-gap:checked + label::after,
.form-check-input[type='radio'].with-gap:checked + label::before,
.form-check-input[type='radio']:checked + label::after,
.form-check-input[type='radio']:checked + label::before,
.form-check-input[type='radio']:not(:checked) + label::after,
.form-check-input[type='radio']:not(:checked) + label::before,
label.btn input[type='radio'].with-gap:checked + label::after,
label.btn input[type='radio'].with-gap:checked + label::before,
label.btn input[type='radio']:checked + label::after,
label.btn input[type='radio']:checked + label::before,
label.btn input[type='radio']:not(:checked) + label::after,
label.btn input[type='radio']:not(:checked) + label::before {
  -webkit-border-radius: 50%;
  border-radius: 50%;
}

.form-check-input[type='radio'] + label::after,
.form-check-input[type='radio'] + label::before,
label.btn input[type='radio'] + label::after,
label.btn input[type='radio'] + label::before {
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  margin: 4px;
  width: 16px;
  height: 16px;
  z-index: 0;
  -webkit-transition: 0.28s ease;
  -o-transition: 0.28s ease;
  transition: 0.28s ease;
}

.form-check-input[type='radio'] {
  display: none;
}
