nav.fixed-bottom {
  background-color: #ffffff;
}

nav.fixed-bottom {
  height: 15%;
  max-height: 15%;
  transition: max-height 0.25s ease-out;
}
nav.fixed-bottom.open {
  height: inherit;
  max-height: 100%;
  transition: max-height 0.25s ease-in-out;
}

.fixed-bottom.open .btn.btn-default {
  color: #ffca6d !important;
}

.fixed-bottom.open .form-control {
  background-color: #ffffff;
  border-bottom: none;
  padding: 0px;
}

nav.fixed-bottom .content {
  display: none;
}

nav.fixed-bottom.open .content {
  display: block;
}

.fixed-bottom.closed {
  background-image: linear-gradient(#ffca6d, #ffca6d);
  cursor: pointer;
}

.fixed-bottom.closed {
  margin-right: -100px;

  margin-left: auto;

  border-radius: 10% 0%;

  width: 200px;
  height: 200px;

  margin-bottom: -165px;
}
.fixed-bottom.closed h5 {
  font-size: 1.1rem;
  margin-left: 25px;
  margin-top: 10px;
  color: #fff;
}
.fixed-bottom.open .short-info {
  display: none;
}

.fixed-bottom.closed .details {
  display: none;
}

.fixed-bottom.open {
  border-radius: 90px 0% 0px 0px;
  box-shadow: -6px -2px 10px 0px rgba(21, 27, 38, 0.15);
}

.fixed-bottom.open .pane .table th,
.table td {
  //padding: 3px 0px;
}

.fixed-bottom .table {
  text-align: center;
  max-width: 800px;
}

.fixed-bottom .table .form-group {
  margin-left: auto;
  margin-right: auto;
  margin-top: 0px;
}

@media (max-width: 768px) {
  .fixed-bottom h5 {
    font-size: 0.9rem;
  }
  .fixed-bottom .form-group > input {
    width: 100px;
    margin-left: auto;
    margin-right: auto;
  }
}

.details li {
  list-style: none;
  margin: 10px;
}

.details {
  text-align: center;
}

.details .form-group {
  margin-left: auto;

  margin-right: auto;
}

@media (max-width: 768px) {
  .closed .btn {
    position: relative;
    margin-top: -30px;
  }
  .costs {
    margin-top: 20px;
  }
}

@media (min-width: 768px) {
  .closed .btn {
    position: absolute;
  }
}
