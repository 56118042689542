.DayPickerInput {
  display: block;
}

.DayPickerInput input {
  margin-top: 0rem;
}

.form-group label.selectdate {
  top: 56px;
}

.form-group label.selectdate:not(.empty) {
  top: 60px;
  transition: none;
  transform: none;
  padding-left: 0px;
}
