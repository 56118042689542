.eventlist {
  //display: flex;
  max-width: 800px;
  margin-left: auto;
  margin-right: auto;
}

.eventlist .pane {
  border: none;
}

.eventlist .pane .head {
  font-size: 18px;
  color: #666666;
  //background-color: #fcdede; // Kathy und Lena 27.3.20
  //background-color: #fff4f4;
  background-color: rgb(251, 251, 251);
  padding-left: 20px !important;
  border-bottom: 0px;
}

.eventlist .body {
  padding: 0px !important;
}

.eventlist .list-group-item:first-child {
  margin-top: 0px;
  border-top-left-radius: 0px;
  border-top-right-radius: 0px;
}

.eventList-item .eventList-date {
  color: $primary !important;
  //border-right: 1px solid #90A2B3;
}

/*.eventList-item:hover .eventList-date {
  background-color: #90A2B3;
  color: #ffffff;
}*/

.eventList-item:hover {
  box-shadow: rgba(0, 0, 0, 0.12) 0px 2px 5px 0px !important;
}
