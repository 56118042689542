@font-face {
  font-family: 'Circular';
  src: url('fonts/Circular-Std-Book.ttf');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'SourceSansProRegular';
  src: url('fonts/SourceSansPro-Regular.otf');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Barlow-Regular';
  src: url('fonts/Barlow-Regular.otf');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Barlow-Medium';
  src: url('fonts/Barlow-Medium.otf');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Barlow-Bold';
  src: url('fonts/Barlow-Bold.otf');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Barlow-SemiBold';
  src: url('fonts/Barlow-SemiBold.otf');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Amaranth-Regular';
  src: url('fonts/Amaranth-Regular.otf');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'HammersmithOne-Regular';
  src: url('fonts/HammersmithOne-Regular.ttf');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'SourceSansProLight';
  src: url('fonts/SourceSansPro-Light.otf');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'RobotoRegular';
  src: url('fonts/Roboto-Regular.ttf');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'RobotoLight';
  src: url('fonts/Roboto-Light.ttf');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'GothamLight';
  src: url('fonts/GothamLight.ttf');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'GothamMedium';
  src: url('fonts/GothamMedium.ttf');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'SafiarSignature';
  src: url('fonts/SafiarSignature.ttf');
  font-weight: normal;
  font-style: normal;
}


@font-face {
  font-family: 'Nunito Sans';
  src: url('fonts/NunitoSans-Black.ttf');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Nunito Sans';
  src: url('fonts/NunitoSans-BlackItalic.ttf');
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: 'Nunito Sans';
  src: url('fonts/NunitoSans-Bold.ttf');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'Nunito Sans';
  src: url('fonts/NunitoSans-BoldItalic.ttf');
  font-weight: bold;
  font-style: italic;
}

@font-face {
  font-family: 'Nunito Sans';
  src: url('fonts/NunitoSans-ExtraBold.ttf');
  font-weight: bolder;
  font-style: normal;
}

@font-face {
  font-family: 'Nunito Sans';
  src: url('fonts/NunitoSans-ExtraBoldItalic.ttf');
  font-weight: bolder;
  font-style: italic;
}

@font-face {
  font-family: 'Nunito Sans';
  src: url('fonts/NunitoSans-ExtraLight.ttf');
  font-weight: lighter;
  font-style: normal;
}

@font-face {
  font-family: 'Nunito Sans';
  src: url('fonts/NunitoSans-ExtraLightItalic.ttf');
  font-weight: lighter;
  font-style: italic;
}

@font-face {
  font-family: 'Nunito Sans';
  src: url('fonts/NunitoSans-Italic.ttf');
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: 'Nunito Sans';
  src: url('fonts/NunitoSans-Light.ttf');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'Nunito Sans';
  src: url('fonts/NunitoSans-LightItalic.ttf');
  font-weight: 300;
  font-style: italic;
}

@font-face {
  font-family: 'Nunito Sans';
  src: url('fonts/NunitoSans-Regular.ttf');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Nunito Sans';
  src: url('fonts/NunitoSans-SemiBold.ttf');
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: 'Nunito Sans';
  src: url('fonts/NunitoSans-SemiBoldItalic.ttf');
  font-weight: 600;
  font-style: italic;
}