.no-suggestions {
  color: #eee;
  padding: 0.5rem;
}

.suggestions {
  border: 1px solid #eee;
  list-style: none;
  margin-top: 5px;
  margin-bottom: 5px;
  max-height: 300px;
  overflow-y: auto;
  padding-left: 0;
  width: 100%;
  border-radius: 4px;
  -webkit-box-shadow: 10px 10px 30px -20px #b6b6b6;
  -moz-box-shadow: 10px 10px 30px -20px #b6b6b6;
  box-shadow: 10px 10px 30px -20px #b6b6b6;
}

.suggestions li {
  padding: 0.5rem;
}

.suggestion-active,
.suggestions li:hover {
  background-color: #fbfbfb;
  color: #000000;
  cursor: pointer;
}

.suggestions li:not(:last-of-type) {
  border-bottom: 1px solid #eee;
}

// ---------------------------
// show placeholder

[placeholder]:empty::before {
  content: attr(placeholder);
  color: #b6b6b6;
}

[placeholder]:empty:focus::before {
  content: '';
}
