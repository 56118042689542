.form-control,
.Select-control,
.Select.is-focused > .Select-control,
.Select.is-focused:not(.is-open) > .Select-control {
  border: none;
  border-bottom: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: 0%;
}

.Select-menu-outer {
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12);
}

.form-control {
  font-size: 0.875rem;
  height: 34px;
  border: 1px solid #B7B7B7 !important;
  font-family: 'Nunito Sans';
}

.form-control a {
  cursor: pointer;
}

//if someone pastes an image e.g. in check availability of service providers
.form-control img {
  max-width: 100%;
}

.form-control:focus {
  box-shadow: none;
}
.form-control,
.form-control:focus {
  border: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: 4px;
}
.form-control:hover,
.form-control:focus,
.Select-control:hover {
  box-shadow: none;
}

.form-control:disabled {
  background-color: #f9f9f9;
}

.form-control.disabled {
  background-color: #f9f9f9;
}

.form-control input {
  background-color: transparent;
}

.form-group input:not([value='']) ~ label {
  margin-top: 10px;
  top: -5px;
  margin-left: 2px;
}

.form-group input[type='text'] + label,
.form-group input[type='password'] + label,
.form-group input[type='email'] + label {
  pointer-events: none;
  margin-left: 2px;
}

.form-group input:focus ~ label,
.form-group label.focused {
  //transition: all 450ms cubic-bezier(0.23, 1, 0.32, 1) 0ms;
  z-index: 1;
  //transform: scale(1) translate(0px, -36px);
  //transform-origin: left top;
  color: $primary  !important;
  font-family: 'Nunito Sans';
  //padding-left: 0px;
}

.form-group {
  margin-bottom: 0px !important;
  border: none;
}

/* Labels unter Input-Feldern */
.col-form-label {
  font-size: 0.9em;
  padding-left: 0px;
  padding-bottom: 0;
  color: #6E6E6E;
  display: flex;
  width: 100%;
}

.form-group label.blurred {
  top: -6px;
}

input {
  text-rendering: auto;
  color: initial;
  letter-spacing: normal;
  word-spacing: normal;
  text-transform: none;
  text-indent: 0px;
  text-shadow: none;
  display: inline-block;
  text-align: start;
  margin: 0em;
  font: 400 11px system-ui;
}

input {
  -webkit-writing-mode: horizontal-tb !important;
}

textarea {
  min-height: 35px;
}


.gpw {
    padding: 0px;
}

.gpw .popover {
    z-index: 100;
    margin-top: 6px;
    box-shadow: rgba(0,0,0,.15) 0 0 0 1px,rgba(0,0,0,.15) 0 8px 16px;
    background-color: #fff;
    padding: 12px;
    border-radius: 4px;
    position: relative;
}