.search-bar .col-form-label {
  color: #ffffff;
}

.search-bar .form-control {
  padding-left: 23px;
}

.search-bar input,
.search-bar .form-control:focus {
  border-bottom: none;
  color: #ffffff;
}

ul li .search-bar .form-control:focus ~ ul li {
  display: none;
}

/*.search-bar input:focus {
  border-color: $bookitup-deep-orange;
}

.search-bar input {
  -webkit-transition: border-color .15s ease-in-out,-webkit-box-shadow .15s ease-in-out;
  transition: border-color .15s ease-in-out,-webkit-box-shadow .15s ease-in-out;
  -o-transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
  transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
  transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out,-webkit-box-shadow .15s ease-in-out;
}
*/

.search-bar input,
.search-bar label {
  float: right;
}

.search-bar .form-group label {
  top: -32px;
  left: 26px;
  font-size: 1rem;
}

.search-bar .form-control:focus {
  width: 100%;
  padding-left: 28px;
}

.search-bar.open .col-form-label {
  display: none;
}

.search-bar.open svg {
  margin-bottom: 3px;
}

.search-bar.open .btn-link {
  color: #ffffff;
}

.nav-item .search-bar a {
  padding: 0px 0px !important;
}

.search-bar .btn-primary {
  box-shadow: none;
  background-color: rgba(255, 216, 111, 0.47);
}
.search-bar .btn-primary:hover,
.search-bar .btn-primary.active {
  box-shadow: 0 5px 11px 0 rgba(0, 0, 0, 0.18), 0 4px 15px 0 rgba(0, 0, 0, 0.15);
}

.search-bar input {
  background-color: transparent;
}
.search-bar.open input {
  background-color: #ffffff69;
}

.search-bar .form-group input:focus ~ label,
.search-bar.form-group label.focused {
  border-bottom: none;
}

.search-bar.closed {
  cursor: pointer;
}

@media (min-width: 768px) {
  .search-bar.closed {
    display: none;
  }
}

.mobile-search,
.search {
  height: 40px;
}

.navbar .dropdown .search-bar label {
  display: none;
}

.search-bar.open {
  display: block;
}

nav .mobile-search .form-group > input,
nav .search .form-group > input {
  margin-top: -33px;
}

.search-bar .form-group {
  margin-top: 1px;
}
