.alert {
  margin: 5px;
}

.alert .btn {
  padding-left: 0px;
  text-decoration: underline;
  font-size: 0.875rem;
  margin-top: -2px;
}

.breadcrumb-item a.nav-link {
  padding: 0;
  display: inline-block;
}

.modal-title {
  width: 100%;
}

/* Solves scrolling problem, if modal was not closed properly (.modal-open {overflow: scroll} was set on body) */
/* Activates scrolling of content if modal is active */
.modal-open {
  overflow: unset;
}

// remove blue border on Chrome
button.list-group-item:focus {
  outline: none;
}

@media (min-width: 768px) {
  .modal-xl {
    max-width: 800px;
  }
}
