.react-pdf__Page__canvas {
  width: 100% !important;
  max-width: 1000px;
  height: unset !important;
  margin-left: auto;
  margin-right: auto;
  border: 1px solid #e5e5e5;
  border-radius: 4px;
}

.view-file button.btn.btn-secondary {
  padding: 3px;
  font-size: 0.8rem;
}

.modal-dialog.modal-xs {
  max-width: 250px !important;
}


.mantine-Modal-modal {
  border-top: 10px solid #FDA632;
  border-radius: 10px;
  // background-image: linear-gradient(white, white), linear-gradient(88deg, #FFBA5E 0%, #FF6B69 100%);
  background-clip: padding-box, border-box;
}
