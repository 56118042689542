.breadcrumb {
  background-color: rgba(0, 0, 0, 0.03);
  margin-bottom: 0;
}

.breadcrumb li {
  font-size: 12px;
}

.breadcrumb-item.active {
  color: #000000;
}
