.pipeline {
  height: 16px;
  position: relative;
  border-radius: 6px 0 0 6px;
  align-self: center;
  display: flex;
  align-items: center;
  margin-left: 3px;
  margin-right: 3px;
}

.pipeline .text {
  font-size: 6pt;
  padding: 5px 10px 5px 10px;
}

.pipeline.recommendation {
  width: 107px;
  margin-right: 6px;
}

@media (max-width: 600px) {
  .pipeline.recommendation {
    width: 60px;
    margin-top: 10px;
  }
}

.pipeline.start .text {
  margin-left: 5px;
}

.pipeline.start {
  z-index: 2;
}

.pipeline.middle {
  z-index: 1;
  margin-left: -5px;
}

.pipeline.end {
  z-index: 0;
  border-radius: 6px 6px 6px 6px;
}

.pipeline.recommendation.end {
  margin-left: -5px;
}

.pipeline.start:before,
.pipeline.middle:before {
  content: '';
  position: absolute;
  right: -8px;
  bottom: 0;
  width: 0;
  height: 0;
  border-top: 8px solid transparent;
  border-bottom: 8px solid transparent;
}

.pipeline.available {
  background: #80ba7c;
  color: #d9f1d9;
}

.pipeline.available:before {
  border-left: 9px solid #80ba7c;
}

.pipeline.blocked {
  background: #e8b470;
  color: #fcf3de;
}

.pipeline.blocked:before {
  border-left: 9px solid #e8b470;
}

.pipeline.unknown {
  background: #6f8297;
  color: #dfe4f9;
}

.pipeline.unknown:before {
  border-left: 9px solid #dfe4f9;
}

.pipeline.recommendation.unknown {
  background: #e8e8e8;
  color: #5e5e5e;
}

.pipeline.recommendation.unknown:before {
  border-left: 9px solid #e8e8e8;
}

.pipeline.unavailable {
  background: #e8e8e8;
  color: #5e5e5e;
}

.pipeline.unavailable:before {
  border-left: 9px solid #e8e8e8;
}

.pipeline.open {
  background: #dfe4f9;
  color: #6f8297;
}

.pipeline.open:before {
  border-left: 9px solid #dfe4f9;
}

.pipeline.offered {
  background: #fcf3de;
  color: #e8b470;
}

.pipeline.offered:before {
  border-left: 9px solid #fcf3de;
}

.pipeline.booked {
  background: #d9f1d9;
  color: #80ba7c;
}

.pipeline.booked:before {
  border-left: 9px solid #d9f1d9;
}

.pipeline.canceled {
  background: #d6524d;
  color: #ffcfcc;
}

.pipeline.canceled:before {
  border-left: 9px solid #d6524d;
}

.pipeline.data-transmitted {
  background: #d9f1d9;
  color: #80ba7c;
}

.pipeline.data-transmitted:before {
  border-left: 9px solid #d9f1d9;
}

.pipeline.data-not-transmitted {
  background: #ffcfcc;
  color: #d6524d;
}

.pipeline.data-not-transmitted:before {
  border-left: 9px solid #ffcfcc;
}

.pipeline.stopped {
  border-radius: 50%;
  width: 12px;
  height: 12px;
  justify-content: center;
  display: flex;
  align-items: center;
}

.pipeline.stopped.success {
  background-color: #80ba7c;
  color: #d9f1d9;
  font-size: 7px;
}

.pipeline.stopped.failed {
  background-color: #d6524d;
  color: #ffffff;
}
