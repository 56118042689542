.ua-checkbox-checkboxLabel {
  font-size: 16px;
  color: #9499a7;
}

.checkbox-checkboxInput {
  position: absolute;
  opacity: 0;
  left: -99999px;
}

.checkbox-checkboxLabel {
  display: inline-block;
  position: relative;
  padding-left: 20px;
  font-size: 14px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  /* user-select: none; */
}

.checkbox-checkbox {
  display: inline-block;
  cursor: pointer;
  margin-bottom: 0px;
}

.checkbox-checkboxLabel::before {
  content: '';
  box-sizing: border-box;
  border: 2px solid #e6e7f0;
  position: absolute;
  width: 16px;
  height: 16px;
  top: 3px;
  left: 0;
  transition: border-color 0.2s ease-in;
}

.checkbox-checkboxInput:hover + .checkbox-checkboxLabel::before,
.checkbox-checkboxInput:focus + .checkbox-checkboxLabel::before {
  border-color: $primary;
  outline: none;
}

.checkbox-checkboxLabel::before {
  content: '';
  box-sizing: border-box;
  border: 2px solid #e6e7f0;
  position: absolute;
  width: 16px;
  height: 16px;
  top: 3px;
  left: 0;
  transition: border-color 0.2s ease-in;
}

.checkbox-checkboxLabel::before {
  content: '';
  box-sizing: border-box;
  border: 2px solid #e6e7f0;
  position: absolute;
  width: 16px;
  height: 16px;
  top: 3px;
  left: 0;
  transition: border-color 0.2s ease-in;
}

.checkbox-checkboxInput:checked + .checkbox-checkboxLabel::before {
  background: $primary
    url('data:image/svg+xml,%3Csvg width%3D%2220%22 height%3D%2218%22 xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E  %3Cpath d%3D%22M8.536 9.778L5.71 6.95a.994.994 0 0 0-1.417-.002.999.999 0 0 0 .002 1.417l3.532 3.53a.994.994 0 0 0 .708.295.985.985 0 0 0 .708-.292l6.187-8.187a1.002 1.002 0 0 0 .005-1.42 1.001 1.001 0 0 0-1.42.006l-5.479 7.48z%22    fill%3D%22%23fff%22 fill-rule%3D%22evenodd%22 %2F%3E%3C%2Fsvg%3E')
    center -1px no-repeat;
  border: 2px solid $primary;
}

/* Disable Start */
.checkbox-checkboxInput:disabled + .checkbox-checkboxLabel::before {
  border: 2px solid #e6e7f0;
  cursor: not-allowed;
}

.checkbox-checkboxInput:checked:disabled + .checkbox-checkboxLabel::before {
  border: 2px solid #7d7d7d;
  background: #7d7d7d
    url('data:image/svg+xml,%3Csvg width%3D%2220%22 height%3D%2218%22 xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E  %3Cpath d%3D%22M8.536 9.778L5.71 6.95a.994.994 0 0 0-1.417-.002.999.999 0 0 0 .002 1.417l3.532 3.53a.994.994 0 0 0 .708.295.985.985 0 0 0 .708-.292l6.187-8.187a1.002 1.002 0 0 0 .005-1.42 1.001 1.001 0 0 0-1.42.006l-5.479 7.48z%22    fill%3D%22%23fff%22 fill-rule%3D%22evenodd%22 %2F%3E%3C%2Fsvg%3E')
    center -1px no-repeat;
}
/* Disable End */
