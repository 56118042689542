.code {
  font-family: courier;
  border: none;
}

.step.haspredecessor::before {
  content: '';
  border-left: 1px solid #dfdfdf;
  position: absolute;
  left: 32px;
  margin-top: -38px;
  height: 21px;
}

.step {
  border-radius: 4px;
  padding: 16px;
  border: 1px solid #e5e5e5;
}
