.section-header {
  display: flex;
  background-color: #fbfbfb;
  border: 1px solid rgba(0, 0, 0, 0.125);
  flex-direction: column;
  justify-content: space-around;
  width: 150px;
  border-bottom: none;
  height: 30px;
  margin-bottom: -1px;
  border-radius: 4px 4px 0px 0px;
  color: #9ca6af;
  text-align: center;
  position: relative;
  z-index: 0;
  padding-left: 5px;
  padding-right: 5px;
}
