.actions-left-hover-item {
  padding-left: 30px;
}

.actions-left-hover-item:hover {
  padding-left: unset;
}

.actions-left-hover-item .actions-for-item {
  display: none;
}

.actions-left-hover-item:hover .actions-for-item {
  display: flex;
  padding-left: unset;
}

.actions-right-hover-item {
  padding-right: 30px;
}

.actions-right-hover-item:hover {
  padding-right: unset;
}

.actions-right-hover-item .actions-for-item {
  display: none;
}

.actions-right-hover-item:hover .actions-for-item {
  display: flex;
  padding-right: unset;
}

.overflow-ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.non-relative {
  position: static !important;
}

.description {
  color: #666666;
  font-size: 12px;
}

.heading {
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 5px;
}

.padding-5 {
  padding: 5px;
}

.box {
  border: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: 4px;
  padding: 5px;
  background-color: #ffffff;
}

.rounded {
  border-radius: 4px;
}

.placeholder-xs > placeholder {
  font-size: 10px !important;
  font-weight: normal;
}