.switch {
  width: 42px !important;
  height: 0.9375rem !important;
  top: 5px;
}

.switch span {
  width: 22px !important;
  height: 22px !important;
  top: -5px !important;
}
